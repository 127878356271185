<template>
	<!-- Start Template Root Div -->
	<div>
		<header class="w-full sticky top-0 z-10">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div
						class="w-full flex justify-between items-center p-2 text-white"
					>
						<div class="w-1/4 text-white p-5">
							<WaveLogo
								class="cursor-pointer h-16 md:h-12 lg:h-16"
							/>
						</div>
						<div class="hidden md:block">
							<h1 class="text-2xl">
								Sign Waiver
							</h1>
						</div>
						<div class="w-1/4 text-right">
							<p class="m-0">
								{{ new Date() | moment("dddd Do MMMM") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>

		<main class="container mx-auto py-2 lg:p-8 lg:py-3">
			<!--content-->
			<div
				class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none mb-10"
			>
				<!--header-->
				<div
					class="hidden md:flex border-b border-solid border-gray-300 rounded-t"
				>
					<p class="flex-auto p-5 text-2xl font-semibold border-r">
						1. Acknowledgement Of Risk And Waiver
					</p>
					<p class="flex-auto p-5 text-2xl font-semibold border-r">
						2. Sign Waiver
					</p>
					<router-link
						:to="{
							name: 'SurferWaiverVideo',
							query: this.$route.query
						}"
						class="flex-auto p-5 text-2xl font-semibold"
					>
						3. Watch Video
					</router-link>
				</div>

				<!--body-->
				<div class="relative p-6 flex-auto">
					<p class="mb-6 font-bold">
						<!-- For your safety please view the following two videos: -->
						For your safety please watch the relevant video for the
						session you have booked: <br />
						If you are completing this Acknowledgement of Risk and
						Waiver on behalf of someone under the age of 18, please
						ensure that they have also watched the relevant video
					</p>

					<!-- Video -->
					<div class="justify-center items-center">
						<div
							v-if="surfersBay.length > 0"
							class="w-2/3 mx-auto my-6"
						>
							<div class="mb-2 font-semibold">
								<p
									class="inline capitalize"
									v-bind:key="'surfer' + surferIndex"
									v-for="surferIndex in surfersBay"
								>
									{{
										surfers[surferIndex].firstName +
											" " +
											surfers[surferIndex].lastName
									}}
									<span v-if="surfersBay[surferIndex + 1]"
										>,
									</span>
								</p>
							</div>
							<p class="mb-2 font-semibold">
								Please watch this video as you have booked a
								Bay-Based Session.
							</p>
							<div
								class="relative w-full rounded-md shadow overflow-hidden"
							>
								<!-- - Video One - -->
								<video
									v-on:click="playVideo($event.currentTarget)"
									class="cursor-pointer"
									v-on:ended="surfersBayWatched = true"
									width="100%"
								>
									<source
										src="/THE_BAY.mp4"
										type="video/mp4"
									/>
									Your browser does not support the video tag.
								</video>
								<div
									class="player-hidden absolute flex inset-0 w-full h-full bg-white bg-opacity-25 pointer-events-none"
								>
									<img
										class="h-64 m-auto"
										src="/play-img.png"
									/>
								</div>
							</div>
						</div>

						<div
							v-if="surfersReef.length > 0"
							class="w-2/3 mx-auto my-6"
						>
							<div class="mb-2 font-semibold">
								<p
									class="inline capitalize"
									v-bind:key="'surfer' + surferIndex"
									v-for="surferIndex in surfersReef"
								>
									{{
										surfers[surferIndex].firstName +
											" " +
											surfers[surferIndex].lastName
									}}
									<span v-if="surfersReef[surferIndex + 1]"
										>,
									</span>
								</p>
							</div>
							<p class="mb-2 font-semibold">
								Please watch this video as you have booked a
								Reef-Based Session.
							</p>
							<div
								class="relative w-full rounded-md shadow overflow-hidden"
							>
								<!-- - Video Two - -->
								<video
									v-on:click="playVideo($event.currentTarget)"
									class="cursor-pointer"
									v-on:ended="surfersReefWatched = true"
									width="100%"
								>
									<source
										src="/THE_REEF.mp4"
										type="video/mp4"
									/>
									Your browser does not support the video tag.
								</video>
								<div
									class="player-hidden absolute flex inset-0 w-full h-full bg-white bg-opacity-25 pointer-events-none"
								>
									<img
										class="h-64 m-auto"
										src="/play-img.png"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Footer -->
				<div
					class="w-full flex flex-col mb-12 justify-center items-center"
				>
					<button
						@click="checkVideos"
						:disabled="!buttonActivated()"
						class="w-9/12 md:w-6/12 lg:w-3/12 bg-pear-500 disabled:opacity-0 hover:bg-pear-700 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full"
					>
						I confirm I/we have watched the video(s), continue
					</button>
					<p v-if="!buttonActivated()" class="text-lg text-gray-700">
						Please watch the whole video before continuing
					</p>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
	import WaveLogo from "@/components/WaveLogo.vue";
	import { mapActions } from "vuex";

	export default {
		components: {
			WaveLogo
		},
		data: () => ({
			validEmail: false,
			waivers: [],

			surfers: [],

			surfersBay: [],
			surfersBayWatched: false,

			surfersReef: [],
			surfersReefWatched: false,

			emailAddress: "",
			reservation: undefined
		}),
		created() {
			this.checkData();
		},
		methods: {
			// Mapped Store Action
			...mapActions(["surfers/staticUpdateSurfersWaiver"]),

			// Vuex Mapped Actions from reservations Namespace:
			...mapActions({
				getReservation: "reservations/fetchReservation"
			}),

			// Vuex Mapped Actions from accounts Namespace:
			...mapActions({
				updatePartialAccount: "accounts/updatePartialAccount"
			}),

			playVideo(element) {
				if (!element.classList.contains("playing")) {
					element.classList.add("playing");
					element.play();
				} else {
					element.classList.remove("playing");
					element.pause();
				}
			},
			getSurferVideos() {
				this.surfers.map((surfer, index) => {
					if (
						!surfer.hasSeenVideo &&
						surfer.requirements.videoRequiredBay
					)
						this.surfersBay.push(index);

					if (
						!surfer.hasSeenVideo &&
						surfer.requirements.videoRequiredReef
					)
						this.surfersReef.push(index);
				});
			},
			buttonActivated() {
				if (
					(this.surfersBay.length > 0 && !this.surfersBayWatched) ||
					(this.surfersReef.length > 0 && !this.surfersReefWatched)
				)
					return false;
				return true;
			},

			async checkData() {
				// Setup Plugin & Params
				await this.setupData();

				// Re-append Route & Redirect if missing query params
				if (
					!this.validEmail ||
					this.reservation.getWaiversSignable().length < 1
				) {
					this.$router.push({
						name: "SurferWaiver",
						query: this.$route.query
					});
					return;
				}

				// Re-append Route & Redirect if none-selected waiver
				if (
					this.reservation.waiversAdults.length > 1 &&
					this.reservation.selectedFirstName === ""
				) {
					this.$router.push({
						name: "SurferWaiverSelect",
						query: this.$route.query
					});
					return;
				}
			},
			async setupData() {
				if (this.$route.query.emailAddress) {
					// Parse Email Address
					this.emailAddress = this.$route.query.emailAddress
						? this.$route.query.emailAddress
								.replace("..", "@")
								.toLowerCase()
						: "";

					// Get Waivers
					this.reservation = await this.getReservation(
						this.emailAddress
					);
					this.waivers = this.reservation.waivers;

					// Get All Surfers (Minors and First Name)
					this.surfers = this.reservation.getWaiversSignable();

					// Check Email Addresses
					if (
						this.emailAddress ==
						this.reservation.primaryEmailAddress
					)
						this.validEmail = true;

					// Sort the Bay and Reef Surfers
					this.getSurferVideos();

					// Check if the Videos actually need doing then Redirect
					this.checkVideos();
				}
			},
			async checkVideos() {
				if (!this.buttonActivated()) return;

				await this.$asyncForEach(
					this.reservation.getWaiversSignableRaw(),
					async surfer => {
						surfer.hasSeenVideo = true;
						await this["surfers/staticUpdateSurfersWaiver"](surfer);

						// Update the Account's Last-Watch Datetime
						if (surfer.requirements.videoRequiredReef)
							await this.updatePartialAccount({
								uuid: surfer.account,
								video_reef_last_watched: new Date().toISOString()
							});
						else if (surfer.requirements.videoRequiredBay)
							await this.updatePartialAccount({
								uuid: surfer.account,
								video_bay_last_watched: new Date().toISOString()
							});
					}
				);

				this.$router.push({
					name: "SurferWaiverConfirm",
					query: this.$route.query
				});
			}
		}
	};
</script>
